<template>
	<v-row>
		<!-- DIALOG DE VALORES DETALHADOS DO CREDU ACUMULADO -->
		<v-dialog v-model="dialogCredu" scrollable>
			<v-card class="primary">
				<v-card-title class="pl-4 pr-3 white--text">
					<span>Valores Acumulado Credu</span>
					<v-spacer />
				</v-card-title>
				<v-card-text class="pa-0">
					<v-simple-table class="pa-0 rounded-0" dense fixed-header height="70vh">
						<template v-slot:default>
							<thead class="header">
								<tr>
									<th class="text-center">Cód. Filial</th>
									<th nowrap>Filial</th>
									<th class="pr-0">TOTAL CREDU MAR/{{ busca.anoSelecionado }}</th>
									<th class="pr-0">TOTAL CREDU JUN/{{ busca.anoSelecionado }}</th>
									<th class="pr-0">TOTAL CREDU SET/{{ busca.anoSelecionado }}</th>
									<th class="pr-0">TOTAL CREDU DEZ/{{ busca.anoSelecionado }}</th>
									<th>Total Filial</th>
								</tr>
							</thead>
							<tbody class="body text-capitalize">
								<tr v-for="(d, i) in dadosAcumulado.lista" :key="i">
									<td class="text-center">{{ d.idempresa }}</td>
									<td nowrap>{{ d.empresa }}</td>
									<td>{{ d.mes01 | formataDinheiro }}</td>
									<td>{{ d.mes02 | formataDinheiro }}</td>
									<td>{{ d.mes03 | formataDinheiro }}</td>
									<td>{{ d.mes04 | formataDinheiro }}</td>
									<td>{{ d.totalfilial | formataDinheiro }}</td>
								</tr>
							</tbody>
							<tfoot class="footThree text-capitalize" v-if="dadosAcumulado.lista != null">
								<tr class="font-weight-bold">
									<td colspan="2">Total:</td>
									<td>{{ dadosAcumulado.totalmes01 | formataDinheiro }}</td>
									<td>{{ dadosAcumulado.totalmes02 | formataDinheiro }}</td>
									<td>{{ dadosAcumulado.totalmes03 | formataDinheiro }}</td>
									<td>{{ dadosAcumulado.totalmes04 | formataDinheiro }}</td>
									<td>{{ dadosAcumulado.lista.map(v => v.totalfilial).reduce((a, v) => a + v, 0) |
										formataDinheiro }}</td>
								</tr>
							</tfoot>
						</template>

					</v-simple-table>
				</v-card-text>
				<v-divider />
				<v-card-actions class="pa-3">
					<v-spacer />
					<v-btn :loading="carregando3" class="px-5" color="primary" elevation="0"
						@click="dialogCredu = false">Fechar</v-btn>
					<v-spacer />
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- DIALOG DE VALORES DETALHADOS DO CREDU ACUMULADO RECEBIDO -->
		<v-dialog v-model="dialog" scrollable>
			<v-card class="primary">
				<v-card-title class="pl-4 pr-3 white--text">
					<span>Valores Totais Recebidos Credu</span>
					<v-spacer />
				</v-card-title>
				<v-card-text class="pa-0">
					<v-simple-table class="pa-0 rounded-0" dense fixed-header height="70vh">
						<template v-slot:default>
							<thead class="header">
								<tr>
									<th class="text-center">Cód. Filial</th>
									<th>Filial</th>
									<th v-for="(m, i) in 12" :key="i">Mês {{ m }}/{{ busca.anoSelecionado }}</th>
									<th>Total Ano/{{ busca.anoSelecionado }}</th>
								</tr>
							</thead>
							<tbody class="body text-capitalize">
								<tr v-for="(d, index) in empresasAgrupadas" :key="index">
									<td nowrap class="text-center">{{ d.idempresa }}</td>
									<td nowrap>{{ d.empresa | formataTextoPedido }}</td>
									<td nowrap v-for="mes in d.meses" :key="mes">{{ mes | formataDinheiro }}</td>
									<td nowrap>{{ d.total | formataDinheiro }}</td>
								</tr>
							</tbody>
							<tfoot class="foot text-capitalize" v-if="dadosRecebido.totalmes != null">
								<tr class="font-weight-bold">
									<td colspan="2">Total:</td>
									<td>{{ dadosRecebido.totalmes["01"] | formataDinheiro }}</td>
									<td>{{ dadosRecebido.totalmes["02"] | formataDinheiro }}</td>
									<td>{{ dadosRecebido.totalmes["03"] | formataDinheiro }}</td>
									<td>{{ dadosRecebido.totalmes["04"] | formataDinheiro }}</td>
									<td>{{ dadosRecebido.totalmes["05"] | formataDinheiro }}</td>
									<td>{{ dadosRecebido.totalmes["06"] | formataDinheiro }}</td>
									<td>{{ dadosRecebido.totalmes["07"] | formataDinheiro }}</td>
									<td>{{ dadosRecebido.totalmes["08"] | formataDinheiro }}</td>
									<td>{{ dadosRecebido.totalmes["09"] | formataDinheiro }}</td>
									<td>{{ dadosRecebido.totalmes["10"] | formataDinheiro }}</td>
									<td>{{ dadosRecebido.totalmes["11"] | formataDinheiro }}</td>
									<td>{{ dadosRecebido.totalmes["12"] | formataDinheiro }}</td>
									<td>{{ dadosRecebido.total | formataDinheiro }}</td>
								</tr>
							</tfoot>
						</template>
					</v-simple-table>


				</v-card-text>
				<v-divider />
				<v-card-actions class="pa-3">
					<v-spacer />
					<v-btn :loading="carregando" class="px-5" color="primary" elevation="0"
						@click="dialog = false">Fechar</v-btn>
					<v-spacer />
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- DIALOG DE VALORES DETALHADOS DO COMPARATIVO DO CREDU COM CX A PRAZO -->
		<v-dialog v-model="dialogCX" scrollable>
			<v-card class="primary">
				<v-card-title class="pl-4 pr-3 white--text">
					<span>Detalhe de Comparativo de Credu com Caixa à Prazo</span>
					<v-spacer />
				</v-card-title>
				<v-card-text class="pa-0">
					<v-simple-table class="pa-0 rounded-0" dense fixed-header height="70vh">
						<template v-slot:default>
							<thead class="header">
								<tr>
									<th class="text-center">Cód. Filial</th>
									<th class="text-center">Filial</th>
									<th class="text-center">OUT/{{ anoAnterior }}</th>
									<th class="text-center">NOV/{{ anoAnterior }}</th>
									<th class="text-center">DEZ/{{ anoAnterior }}</th>
									<th class="text-center">JAN/{{ busca.anoSelecionado }}</th>
									<th class="text-center">FEV/{{ busca.anoSelecionado }}</th>
									<th class="text-center">MAR/{{ busca.anoSelecionado }}</th>
									<th class="text-center">ABR/{{ busca.anoSelecionado }}</th>
									<th class="text-center">MAI/{{ busca.anoSelecionado }}</th>
									<th class="text-center">JUN/{{ busca.anoSelecionado }}</th>
									<th class="text-center">JUL/{{ busca.anoSelecionado }}</th>
									<th class="text-center">AGO/{{ busca.anoSelecionado }}</th>
									<th class="text-center">SET/{{ busca.anoSelecionado }}</th>
									<th class="text-center">TOTAL A PRAZO</th>
								</tr>
							</thead>
							<tbody class="body text-capitalize">
								<tr v-for="(d, index) in empresasAgrupadasCX" :key="index">
									<td nowrap class="text-center">{{ d.idempresa }}</td>
									<td nowrap class="text-center">{{ d.empresa }}</td>
									<td nowrap v-for="(valor, data) in d.meses" :key="data" class="text-center">
										{{ valor | formataDinheiro }}
									</td>
									<td nowrap class="text-center">{{ d.total | formataDinheiro }}</td>
								</tr>
							</tbody>
							<tfoot class="foot footBody text-capitalize" v-if="dadosCXPrazo != null">
								<tr class="font-weight-bold">
									<td></td>
									<td class="text-center">Totais:</td>
									<td>{{ dadosCXPrazo.totalmes["10"] | formataDinheiro }}</td>
									<td>{{ dadosCXPrazo.totalmes["11"] | formataDinheiro }}</td>
									<td>{{ dadosCXPrazo.totalmes["12"] | formataDinheiro }}</td>
									<td>{{ dadosCXPrazo.totalmes["01"] | formataDinheiro }}</td>
									<td>{{ dadosCXPrazo.totalmes["02"] | formataDinheiro }}</td>
									<td>{{ dadosCXPrazo.totalmes["03"] | formataDinheiro }}</td>
									<td>{{ dadosCXPrazo.totalmes["04"] | formataDinheiro }}</td>
									<td>{{ dadosCXPrazo.totalmes["05"] | formataDinheiro }}</td>
									<td>{{ dadosCXPrazo.totalmes["06"] | formataDinheiro }}</td>
									<td>{{ dadosCXPrazo.totalmes["07"] | formataDinheiro }}</td>
									<td>{{ dadosCXPrazo.totalmes["08"] | formataDinheiro }}</td>
									<td>{{ dadosCXPrazo.totalmes["09"] | formataDinheiro }}</td>
									<td class="text-center">{{ dadosCXPrazo.totalvendaprazo | formataDinheiro }}
									</td>
								</tr>
							</tfoot>
						</template>
					</v-simple-table>


				</v-card-text>
				<v-divider />
				<v-card-actions class="pa-3">
					<v-spacer />
					<v-btn :loading="carregando" class="px-5" color="primary" elevation="0"
						@click="dialogCX = false">Fechar</v-btn>
					<v-spacer />
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- FILTRO DE DATA -->
		<v-col cols="12">
			<v-card>
				<v-card-text>
					<v-row no-gutters class="mb-n6">
						<v-col class="pr-2">
							<v-select :items="datas" item-text="name" item-value="value" label="Período" outlined dense
								v-model="busca.data"></v-select>
						</v-col>
						<v-col cols="auto" class="pl-2">
							<v-btn :disabled="carregando" class="mt-1" color="primary" elevation="0" fab rounded x-small
								@click="listar()">
								<v-icon>mdi-magnify</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-col>

		<v-col cols="12">


			<v-card class="primary">
				<v-card-title class="white--text">
					Resumo Credu {{ busca.anoSelecionado }}
				</v-card-title>
				<v-divider class="white" />
				<v-card-text class="pa-2">

					<!-- CARD ESTATICO CREDU 01 -->
					<v-card active-class="primary white--text rounded-0 my-card" @click="dialogCredu01 = true"
						ripple="false" elevation="0" tile>
						<v-card-title class="py-4">
							<v-row align="center" no-gutters>
								<v-col cols="4" class="text-start text-h6 font-weight-bold">Credu Março/{{
									busca.anoSelecionado
								}}
								</v-col>
								<v-col cols="8">
									<v-row v-if="!open">
										<v-col class="text-center font-weight-bold">Total Credu <br />
											<v-chip color="primary" class="mt-1">
												<template v-if="!carregandoSkeleton">
													{{
														dados.firstmonth.map(v =>
															v.total).reduce((a, v) => a + v, 0) | formataDinheiro
													}}
												</template>
												<template v-else>
													<v-progress-circular indeterminate size="16"
														color="white"></v-progress-circular>
												</template>
											</v-chip>
										</v-col>
										<v-col class="text-center font-weight-bold">
											<v-tooltip right color="primary" content-class='custom-tooltip'>
												<template v-slot:activator="{ on }">
													<span v-on="on">Venda 3 Últ. Meses</span>
												</template>
												<span>Dezembro, Janeiro, Fevereiro</span>
											</v-tooltip>
											<br />
											<v-chip color="primary" class="mt-1">
												<template v-if="!carregandoSkeleton">
													{{
														dados.firstmonth.map(v =>
															v.venda).reduce((a, v) => a + v, 0) | formataDinheiro
													}}
												</template>
												<template v-else>
													<v-progress-circular indeterminate size="16"
														color="white"></v-progress-circular>
												</template>
											</v-chip>
										</v-col>
										<v-col class="text-center font-weight-bold">Índice <br />
											<v-chip color="primary" class="mt-1">
												<template v-if="!carregandoSkeleton">
													{{
														((dados.firstmonth.map(v => v.total).reduce((a, v) => a + v,
															0)) /
															(dados.firstmonth.map(v => v.venda).reduce((a, v) => a + v, 0)
																|| 1) * 100).toFixed(2)
													}}%
												</template>
												<template v-else>
													<v-progress-circular indeterminate size="16"
														color="white"></v-progress-circular>
												</template>
											</v-chip>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</v-card-title>
						<v-dialog v-model="dialogCredu01" scrollable height="70vh">
							<v-card class="primary">
								<v-card-title class="pl-4 pr-3 white--text">
									Credu Março/{{ busca.anoSelecionado }}
									<v-spacer />
								</v-card-title>
								<v-card-text class="pa-0">
									<v-skeleton-loader v-if="carregandoSkeleton" type="table-tbody" />
									<v-simple-table v-else dense fixed-header height="70vh" width="70h" class="elevation-0">
										<template v-slot:default>
											<thead class="header">
												<tr>
													<th class="pl-8 pr-0 pt-0 pb-0">Rank</th>
													<th class="pl-4 pr-0 pt-0 pb-0">Filial</th>
													<th>Total Credu</th>
													<th>
														<v-tooltip right color="primary" content-class='custom-tooltip'>
															<template v-slot:activator="{ on }">
																<span v-on="on">Venda 3 Últ. Meses</span>
															</template>
															<span>Dezembro, Janeiro, Fevereiro</span>
														</v-tooltip>
													</th>
													<th>Índice</th>
												</tr>
											</thead>
											<tbody class="body text-capitalize" v-if="dados != null">
												<tr v-for="(d, index) in dados.firstmonth" :key="index">
													<td :style="d.indice >= 0.5 && d.crediario >= 0 ? 'color:#F22233; font-weight: bold;' : 'font-weight: bold'"
														class="pl-8 pr-0 pt-0 pb-0">
														{{ d.rk }}
													</td>
													<td :style="d.indice >= 0.5 && d.crediario >= 0 ? 'color:#F22233; font-weight: bold;' : ''"
														class="pl-4 pr-0 pt-0 pb-0">
														{{ d.empresa }}
													</td>
													<td
														:style="d.indice >= 0.5 ? 'color:#F22233; font-weight: bold' : ''">
														{{ d.total | formataDinheiro }}
													</td>
													<td
														:style="d.indice >= 0.5 ? 'color:#F22233; font-weight: bold;' : ''">
														{{ d.venda | formataDinheiro }}
													</td>
													<td
														:style="d.indice >= 0.5 ? 'color:#F22233; font-weight: bold;' : ''">
														{{ d.indice }}%
													</td>
												</tr>
											</tbody>
											<tfoot class="foot text-capitalize" v-if="dados != null && !carregando">
												<tr class="cursor-normal">
													<td nowrap></td>
													<td nowrap class="bold-text">Totais:</td>
													<td nowrap class="bold-text">{{
														dados.firstmonth.map(v =>
															v.total).reduce((a, v) => a + v, 0) |
														formataDinheiro
													}}
													</td>
													<td nowrap class="bold-text">{{
														dados.firstmonth.map(v =>
															v.venda).reduce((a, v) => a + v, 0) |
														formataDinheiro
													}}
													</td>
													<td nowrap class="bold-text">
														{{
															((dados.firstmonth.map(v => v.total).reduce((a, v) => a + v, 0))
																/
																(dados.firstmonth.map(v => v.venda).reduce((a, v) => a + v, 0)
																	|| 1)
																*
																100).toFixed(2)
														}}%
													</td>
												</tr>
											</tfoot>
											<tbody v-else>
												<tr>
													<td class="text-center" colspan="5">Nenhum registro encontrado.</td>
												</tr>
											</tbody>
										</template>
									</v-simple-table>
								</v-card-text>
								<v-divider />
								<v-card-actions class="pa-3">
									<v-spacer />
									<v-btn :loading="carregando3" class="px-5" color="primary" elevation="0"
										@click="dialogCredu01 = false">Fechar
									</v-btn>
									<v-spacer />
								</v-card-actions>
							</v-card>
						</v-dialog>
					</v-card>

					<v-divider class="primary" />

					<!-- CARD ESTATICO CREDU 02 -->
					<v-card active-class="primary white--text rounded-0 my-card" @click="dialogCredu02 = true"
						ripple="false" elevation="0" tile>
						<v-card-title class="py-4">
							<v-row align="center" no-gutters>
								<v-col cols="4" class="text-start text-h6 font-weight-bold">Credu Junho/{{
									busca.anoSelecionado
								}}
								</v-col>
								<v-col cols="8">
									<v-row v-if="!open">
										<v-col class="text-center font-weight-bold">Total Credu <br />
											<v-chip color="primary" class="mt-1">
												<template v-if="!carregandoSkeleton">
													{{
														dados.secondmonth.map(v =>
															v.total).reduce((a, v) => a + v, 0) | formataDinheiro
													}}
												</template>
												<template v-else>
													<v-progress-circular indeterminate size="16"
														color="white"></v-progress-circular>
												</template>
											</v-chip>
										</v-col>
										<v-col class="text-center font-weight-bold">
											<v-tooltip right color="primary" content-class='custom-tooltip'>
												<template v-slot:activator="{ on }">
													<span v-on="on">Venda 3 Últ. Meses</span>
												</template>
												<span>Março, Abril, Maio</span>
											</v-tooltip>
											<br />
											<v-chip color="primary" class="mt-1">
												<template v-if="!carregandoSkeleton">
													{{
														dados.secondmonth.map(v =>
															v.venda).reduce((a, v) => a + v, 0) | formataDinheiro
													}}
												</template>
												<template v-else>
													<v-progress-circular indeterminate size="16"
														color="white"></v-progress-circular>
												</template>
											</v-chip>
										</v-col>
										<v-col class="text-center font-weight-bold">Índice <br />
											<v-chip color="primary" class="mt-1">
												<template v-if="!carregandoSkeleton">
													{{
														((dados.secondmonth.map(v => v.total).reduce((a, v) => a + v,
															0)) /
															(dados.secondmonth.map(v => v.venda).reduce((a, v) => a + v, 0)
																|| 1) * 100).toFixed(2)
													}}%
												</template>
												<template v-else>
													<v-progress-circular indeterminate size="16"
														color="white"></v-progress-circular>
												</template>
											</v-chip>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</v-card-title>
						<v-dialog v-model="dialogCredu02" scrollable height="70vh">
							<v-card class="primary">
								<v-card-title class="pl-4 pr-3 white--text">
									Credu Junho/{{ busca.anoSelecionado }}
									<v-spacer />
								</v-card-title>
								<v-card-text class="pa-0">
									<v-skeleton-loader v-if="carregandoSkeleton" type="table-tbody" />
									<v-simple-table v-else dense fixed-header height="70vh" width="70h">
										<template v-slot:default>
											<thead class="header">
												<tr>
													<th class="pl-8 pr-0 pt-0 pb-0">Rank</th>
													<th class="pl-4 pr-0 pt-0 pb-0">Filial</th>
													<th>Total Credu</th>
													<th>
														<v-tooltip right color="primary" content-class='custom-tooltip'>
															<template v-slot:activator="{ on }">
																<span v-on="on">Venda 3 Últ. Meses</span>
															</template>
															<span>Março, Abril, Maio</span>
														</v-tooltip>
													</th>
													<th>Índice</th>
												</tr>
											</thead>
											<tbody class="body text-capitalize" v-if="dados != null">
												<tr v-for="(d, index) in dados.secondmonth" :key="index">
													<td :style="d.indice >= 0.5 && d.crediario >= 0 ? 'color:#F22233; font-weight: bold;' : 'font-weight: bold'"
														class="pl-8 pr-0 pt-0 pb-0">
														{{ d.rk }}</td>
													<td :style="d.indice >= 0.5 && d.crediario >= 0 ? 'color:#F22233; font-weight: bold;' : ''"
														class="pl-4 pr-0 pt-0 pb-0">
														{{ d.empresa }}</td>
													<td
														:style="d.indice >= 0.5 ? 'color:#F22233; font-weight: bold' : ''">
														{{ d.total | formataDinheiro }}</td>
													<td
														:style="d.indice >= 0.5 ? 'color:#F22233; font-weight: bold' : ''">
														{{ d.venda | formataDinheiro }}</td>
													<td
														:style="d.indice >= 0.5 ? 'color:#F22233; font-weight: bold;' : ''">
														{{
															(d.indice).toFixed(2) }}%</td>
												</tr>
											</tbody>
											<tfoot class="foot text-capitalize" v-if="dados != null && !carregando">
												<tr class="cursor-normal">
													<td nowrap></td>
													<td nowrap class="bold-text">Totais:</td>
													<td nowrap class="bold-text">{{ dados.secondmonth.map(v =>
														v.total).reduce((a, v) => a + v, 0) |
														formataDinheiro }}</td>
													<td nowrap class="bold-text">{{ dados.secondmonth.map(v =>
														v.venda).reduce((a, v) => a + v, 0) |
														formataDinheiro }}</td>
													<td nowrap class="bold-text">
														{{ ((dados.secondmonth.map(v => v.total).reduce((a, v) => a + v,
															0))
															/
															(dados.secondmonth.map(v => v.venda).reduce((a, v) => a + v, 0)
																||
																1) *
															100).toFixed(2) }}%
													</td>
												</tr>
											</tfoot>
											<tbody v-else>
												<tr>
													<td class="text-center" colspan="5">Nenhum registro encontrado.</td>
												</tr>
											</tbody>
										</template>
									</v-simple-table>
								</v-card-text>
								<v-divider />
								<v-card-actions class="pa-3">
									<v-spacer />
									<v-btn :loading="carregando3" class="px-5" color="primary" elevation="0"
										@click="dialogCredu02 = false">Fechar
									</v-btn>
									<v-spacer />
								</v-card-actions>
							</v-card>
						</v-dialog>
					</v-card>

					<v-divider class="primary" />

					<!-- CARD ESTATICO CREDU 03 -->
					<v-card active-class="primary white--text rounded-0 my-card" @click="dialogCredu03 = true"
						ripple="false" elevation="0" tile>
						<v-card-title class="py-4">
							<v-row align="center" no-gutters>
								<v-col cols="4" class="text-start text-h6 font-weight-bold">Credu Setembro/{{
									busca.anoSelecionado }}</v-col>
								<v-col cols="8">
									<v-row v-if="!open">
										<v-col class="text-center font-weight-bold">Total Credu <br />
											<v-chip color="primary" class="mt-1">
												<template v-if="!carregandoSkeleton">
													{{ dados.thirdmonth.map(v =>
														v.total).reduce((a, v) => a + v, 0) | formataDinheiro
													}}
												</template>
												<template v-else>
													<v-progress-circular indeterminate size="16"
														color="white"></v-progress-circular>
												</template>
											</v-chip>
										</v-col>
										<v-col class="text-center font-weight-bold">
											<v-tooltip right color="primary" content-class='custom-tooltip'>
												<template v-slot:activator="{ on }">
													<span v-on="on">Venda 3 Últ. Meses</span>
												</template>
												<span>Junho, Julho, Agosto</span>
											</v-tooltip><br />
											<v-chip color="primary" class="mt-1">
												<template v-if="!carregandoSkeleton">
													{{ dados.thirdmonth.map(v =>
														v.venda).reduce((a, v) => a + v, 0) | formataDinheiro
													}}
												</template>
												<template v-else>
													<v-progress-circular indeterminate size="16"
														color="white"></v-progress-circular>
												</template>
											</v-chip>
										</v-col>
										<v-col class="text-center font-weight-bold">Índice <br />
											<v-chip color="primary" class="mt-1">
												<template v-if="!carregandoSkeleton">
													{{ ((dados.thirdmonth.map(v => v.total).reduce((a, v) => a + v,
														0)) /
														(dados.thirdmonth.map(v => v.venda).reduce((a, v) => a + v, 0)
															|| 1) * 100).toFixed(2)
													}}%
												</template>
												<template v-else>
													<v-progress-circular indeterminate size="16"
														color="white"></v-progress-circular>
												</template>
											</v-chip>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</v-card-title>
						<v-dialog v-model="dialogCredu03" scrollable height="70vh">
							<v-card class="primary">
								<v-card-title class="pl-4 pr-3 white--text">
									Credu Setembro/{{ busca.anoSelecionado }}
									<v-spacer />
								</v-card-title>
								<v-card-text class="pa-0">
									<v-skeleton-loader v-if="carregandoSkeleton" type="table-tbody" />
									<v-simple-table v-else dense fixed-header height="70vh" width="70h">
										<template v-slot:default>
											<thead class="header">
												<tr>
													<th class="pl-8 pr-0 pt-0 pb-0">Rank</th>
													<th class="pl-4 pr-0 pt-0 pb-0">Filial</th>
													<th>Total Credu</th>
													<th>
														<v-tooltip right color="primary" content-class='custom-tooltip'>
															<template v-slot:activator="{ on }">
																<span v-on="on">Venda 3 Últ. Meses</span>
															</template>
															<span>Junho, Julho, Agosto</span>
														</v-tooltip>
													</th>
													<th>Índice</th>
												</tr>
											</thead>
											<tbody class="body text-capitalize" v-if="dados != null">
												<tr v-for="(d, index) in dados.thirdmonth" :key="index">
													<td :style="d.indice >= 0.5 && d.total >= 0 ? 'color:#F22233; font-weight: bold;' : 'font-weight: bold'"
														class="pl-8 pr-0 pt-0 pb-0">
														{{ d.rk }}</td>
													<td :style="d.indice >= 0.5 && d.total >= 0 ? 'color:#F22233; font-weight: bold;' : ''"
														class="pl-4 pr-0 pt-0 pb-0">
														{{ d.empresa }}</td>
													<td
														:style="d.indice >= 0.5 ? 'color:#F22233; font-weight: bold' : ''">
														{{ d.total | formataDinheiro }}</td>
													<td
														:style="d.indice >= 0.5 ? 'color:#F22233; font-weight: bold' : ''">
														{{ d.venda | formataDinheiro }}</td>
													<td
														:style="d.indice >= 0.5 ? 'color:#F22233; font-weight: bold;' : ''">
														{{
															(d.indice).toFixed(2) }}%</td>
												</tr>
											</tbody>
											<tfoot class="foot text-capitalize" v-if="dados != null && !carregando">
												<tr class="cursor-normal">
													<td nowrap></td>
													<td nowrap class="bold-text">Totais:</td>
													<td nowrap class="bold-text">{{ dados.thirdmonth.map(v =>
														v.total).reduce((a, v) => a + v, 0) |
														formataDinheiro }}</td>
													<td nowrap class="bold-text">{{ dados.thirdmonth.map(v =>
														v.venda).reduce((a, v) => a + v, 0) |
														formataDinheiro }}</td>
													<td nowrap class="bold-text">
														{{ ((dados.thirdmonth.map(v => v.total).reduce((a, v) => a + v,
															0))
															/
															(dados.thirdmonth.map(v => v.venda).reduce((a, v) => a + v, 0)
																|| 1)
															*
															100).toFixed(2) }}%
													</td>
												</tr>
											</tfoot>
											<tbody v-else>
												<tr>
													<td class="text-center" colspan="5">Nenhum registro encontrado.</td>
												</tr>
											</tbody>
										</template>
									</v-simple-table>
								</v-card-text>
								<v-divider />
								<v-card-actions class="pa-3">
									<v-spacer />
									<v-btn :loading="carregando3" class="px-5" color="primary" elevation="0"
										@click="dialogCredu03 = false">Fechar
									</v-btn>
									<v-spacer />
								</v-card-actions>
							</v-card>
						</v-dialog>
					</v-card>

					<v-divider class="primary" />

					<!-- CARD ESTATICO CREDU 04 -->
					<v-card active-class="primary white--text rounded-0 my-card" @click="dialogCredu04 = true"
						ripple="false" elevation="0" tile>
						<v-card-title class="py-4">
							<v-row align="center" no-gutters>
								<v-col cols="4" class="text-start text-h6 font-weight-bold">Credu Dezembro/{{
									busca.anoSelecionado
									}}</v-col>
								<v-col cols="8">
									<v-row v-if="!open">
										<v-col class="text-center font-weight-bold">Total Credu <br />
											<v-chip color="primary" class="mt-1">
												<template v-if="!carregandoSkeleton">
													{{ dados.fourthmonth.map(v =>
														v.total).reduce((a, v) => a + v, 0) | formataDinheiro
													}}
												</template>
												<template v-else>
													<v-progress-circular indeterminate size="16"
														color="white"></v-progress-circular>
												</template>
											</v-chip>
										</v-col>
										<v-col class="text-center font-weight-bold">
											<v-tooltip right color="primary" content-class='custom-tooltip'>
												<template v-slot:activator="{ on }">
													<span v-on="on">Venda 3 Últ. Meses</span>
												</template>
												<span>Setembro, Outubro, Novembro</span>
											</v-tooltip> <br />
											<v-chip color="primary" class="mt-1">
												<template v-if="!carregandoSkeleton">
													{{ dados.fourthmonth.map(v =>
														v.venda).reduce((a, v) => a + v, 0) | formataDinheiro
													}}
												</template>
												<template v-else>
													<v-progress-circular indeterminate size="16"
														color="white"></v-progress-circular>
												</template>
											</v-chip>
										</v-col>
										<v-col class="text-center font-weight-bold">Índice <br />
											<v-chip color="primary" class="mt-1">
												<template v-if="!carregandoSkeleton">
													{{ ((dados.fourthmonth.map(v => v.total).reduce((a, v) => a + v,
														0)) /
														(dados.fourthmonth.map(v => v.venda).reduce((a, v) => a + v, 0)
															|| 1) * 100).toFixed(2)
													}}%
												</template>
												<template v-else>
													<v-progress-circular indeterminate size="16"
														color="white"></v-progress-circular>
												</template>
											</v-chip>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</v-card-title>
						<v-dialog v-model="dialogCredu04" scrollable height="70vh">
							<v-card class="primary">
								<v-card-title class="pl-4 pr-3 white--text">
									Credu Dezembro/{{ busca.anoSelecionado }}
									<v-spacer />
								</v-card-title>
								<v-card-text class="pa-0">
									<v-skeleton-loader v-if="carregandoSkeleton" type="table-tbody" />
									<v-simple-table v-else dense fixed-header height="70vh" width="70h">
										<template v-slot:default>
											<thead class="header">
												<tr>
													<th class="pl-8 pr-0 pt-0 pb-0">Rank</th>
													<th class="pl-4 pr-0 pt-0 pb-0">Filial</th>
													<th>Total Credu</th>
													<th>
														<v-tooltip right color="primary" content-class='custom-tooltip'>
															<template v-slot:activator="{ on }">
																<span v-on="on">Venda 3 Últ. Meses</span>
															</template>
															<span>Setembro, Outubro, Novembro</span>
														</v-tooltip>
													</th>
													<th>Índice</th>
												</tr>
											</thead>
											<tbody class="body text-capitalize" v-if="dados != null">
												<tr v-for="(d, index) in dados.fourthmonth" :key="index">
													<td :style="d.indice >= 0.5 && d.crediario >= 0 ? 'color:#F22233; font-weight: bold;' : 'font-weight: bold'"
														class="pl-8 pr-0 pt-0 pb-0">
														{{ d.rk }}</td>
													<td :style="d.indice >= 0.5 && d.crediario >= 0 ? 'color:#F22233; font-weight: bold;' : ''"
														class="pl-4 pr-0 pt-0 pb-0">
														{{ d.empresa }}</td>
													<td
														:style="d.indice >= 0.5 ? 'color:#F22233; font-weight: bold' : ''">
														{{ d.total | formataDinheiro }}</td>
													<td
														:style="d.indice >= 0.5 ? 'color:#F22233; font-weight: bold' : ''">
														{{ d.venda | formataDinheiro }}</td>
													<td
														:style="d.indice >= 0.5 ? 'color:#F22233; font-weight: bold;' : ''">
														{{
															(d.indice).toFixed(2) }}%</td>
												</tr>
											</tbody>
											<tfoot class="foot text-capitalize" v-if="dados != null && !carregando">
												<tr class="cursor-normal">
													<td nowrap></td>
													<td nowrap class="bold-text">Totais:</td>
													<td nowrap class="bold-text">{{ dados.fourthmonth.map(v =>
														v.total).reduce((a, v) => a + v, 0) |
														formataDinheiro }}</td>
													<td nowrap class="bold-text">{{ dados.fourthmonth.map(v =>
														v.venda).reduce((a, v) => a + v, 0) |
														formataDinheiro }}</td>
													<td nowrap class="bold-text">
														{{ ((dados.fourthmonth.map(v => v.total).reduce((a, v) => a + v,
															0))
															/
															(dados.fourthmonth.map(v => v.venda).reduce((a, v) => a + v, 0)
																||
																1) *
															100).toFixed(2) }}%
													</td>
												</tr>
											</tfoot>
											<tbody v-else>
												<tr>
													<td class="text-center" colspan="5">Nenhum registro encontrado.</td>
												</tr>
											</tbody>
										</template>
									</v-simple-table>
								</v-card-text>
								<v-divider />
								<v-card-actions class="pa-3">
									<v-spacer />
									<v-btn :loading="carregando3" class="px-5" color="primary" elevation="0"
										@click="dialogCredu04 = false">Fechar
									</v-btn>
									<v-spacer />
								</v-card-actions>
							</v-card>
						</v-dialog>
					</v-card>
				</v-card-text>
			</v-card>

			<!-- PAINEL ACUMULADO CREDU -->
			<v-card class="mt-4 primary">
				<v-card-title class="text-h6 white--text">
					Acumulado {{ busca.anoSelecionado }}
				</v-card-title>
				<v-card-text class="pa-2">

					<v-skeleton-loader v-if="carregandoSkeleton" type="table-tbody" />
					<v-simple-table v-else dense fixed-header height="70vh" width="80vh">
						<template v-slot:default>
							<thead class="headerTwo">
								<tr>
									<th>Rank</th>
									<th class="pr-0">Filial</th>
									<th class="pr-0">
										Credu Acumulado
										<v-icon @click="dialogCredu = true" color="primary" class="ml-2"
											size="20">mdi-magnify-plus-outline</v-icon>
									</th>
									<th class="pr-0">Índice s/ Venda</th>
									<th class="pr-0">Venda Acumulada</th>
									<th class="pr-0">Meta</th>
									<th class="pr-0">
										Total Recebido Acumulado
										<v-icon @click="dialog = true" color="primary" class="ml-2"
											size="20">mdi-magnify-plus-outline</v-icon>
									</th>
									<th class="pr-0">Índice de Recebimento</th>
									<th class="pr-0">Saldo a Receber</th>
									<th class="pr-0">Índice Atual</th>
								</tr>
							</thead>
							<tbody class="body text-capitalize" v-if="dados.creduacumulado != null">
								<tr v-for="(d, i) in dados.creduacumulado" :key="i">
									<td>{{ d.rk }}</td>
									<td class="pr-0" nowrap>{{ d.empresa | formataTextoPedido }}</td>
									<td class="pr-0">{{ d.creduacumulado | formataDinheiro }}</td>
									<td class="pr-0">{{ (d.indicesobrevenda) || 0 }}%</td>
									<td class="pr-0">{{ d.vendaacumulada | formataDinheiro }}</td>
									<td class="pr-0">{{ d.meta | formataDinheiro }}</td>
									<td class="pr-0">{{ d.credurecebido | formataDinheiro }}</td>
									<td class="pr-0">{{ (d.indicerecebimento) }}%</td>
									<td class="pr-0">{{ d.saldoreceber | formataDinheiro }}</td>
									<td class="pr-0">{{ (d.indiceatual) || 0 }}%</td>
								</tr>
							</tbody>
							<tfoot class="foot text-capitalize" v-if="dados.creduacumulado != null">
								<tr class="cursor-normal">
									<td nowrap></td>
									<td nowrap class="bold-text">Totais:</td>
									<td nowrap class="bold-text">{{ dados.creduacumulado.map(v =>
										v.creduacumulado).reduce((a, v) => a + v, 0) |
										formataDinheiro }}</td>
									<td nowrap class="bold-text">
										{{ ((dados.creduacumulado.map(v => v.creduacumulado).reduce((a, v) => a + v, 0))
											/
											(dados.creduacumulado.map(v => v.vendaacumulada).reduce((a, v) => a + v, 0) ||
												1) * 100).toFixed(2) }}%
									</td>
									<td nowrap class="bold-text">{{ dados.creduacumulado.map(v =>
										v.vendaacumulada).reduce((a, v) => a + v, 0) |
										formataDinheiro }}</td>
									<td nowrap class="bold-text">{{ dados.creduacumulado.map(v =>
										v.meta).reduce((a, v) => a + v, 0) |
										formataDinheiro }}</td>
									<td nowrap class="bold-text">{{ dados.creduacumulado.map(v =>
										v.credurecebido).reduce((a, v) => a + v, 0) |
										formataDinheiro }}</td>
									<td nowrap class="bold-text">
										{{ ((dados.creduacumulado.map(v => v.credurecebido).reduce((a, v) => a + v, 0))
											/
											(dados.creduacumulado.map(v => v.creduacumulado).reduce((a, v) => a + v, 0) ||
												1) * 100).toFixed(2) }}%
									</td>
									<td nowrap class="bold-text">{{ dados.creduacumulado.map(v =>
										v.saldoreceber).reduce((a, v) => a + v, 0) |
										formataDinheiro }}</td>
									<td nowrap class="bold-text">
										{{ ((dados.creduacumulado.map(v => v.saldoreceber).reduce((a, v) => a + v, 0)) /
											(dados.creduacumulado.map(v => v.vendaacumulada).reduce((a, v) => a + v, 0) ||
												1) * 100).toFixed(2) }}%
									</td>
								</tr>
							</tfoot>
							<tbody v-else>
								<tr>
									<td class="text-center" colspan="10">Nenhum registro encontrado.</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>
			</v-card>

			<!-- PAINEL COMPARATIVO CREDU COM CX A PRAZO -->
			<v-card class="mt-4 primary">
				<v-card-title class="text-h6 white--text">
					Comparativo Credu com Caixa à Prazo - {{ busca.anoSelecionado }}
				</v-card-title>
				<v-card-text class="pa-2">

					<v-skeleton-loader v-if="carregandoSkeleton2" type="table-tbody" />
					<v-simple-table v-else dense fixed-header height="70vh">
						<template v-slot:default>
							<thead class="headerThree">
								<tr>
									<th class="pl-8 pr-0 pt-0 pb-0">Rank</th>
									<th class="pl-4 pr-0 pt-0 pb-0">Filial</th>
									<th class="pl-4 pr-0 pt-0 pb-0">
										TOTAL CREDU {{ busca.anoSelecionado }}
										<v-icon @click="dialogCredu = true" color="primary"
											size="20">mdi-magnify-plus-outline</v-icon>
									</th>
									<th class="pl-4 pr-0 pt-0 pb-0">
										TOTAL VENDA À PRAZO {{ busca.anoSelecionado }}
										<v-icon @click="dialogCX = true" color="primary"
											size="20">mdi-magnify-plus-outline</v-icon>
									</th>
									<th class="pl-4 pr-8 pt-0 pb-0">Índice</th>
								</tr>
							</thead>
							<tbody class="bodyThree text-capitalize" v-if="dadosComparativo.lista != null">
								<tr v-for="(d, i) in dadosComparativo.lista" :key="i">
									<td class="pl-8 pr-0 pt-0 pb-0" nowrap>{{ d.rk }}</td>
									<td class="pl-4 pr-0 pt-0 pb-0" nowrap>{{ d.empresa }}</td>
									<td class="pl-4 pr-0 pt-0 pb-0" nowrap>{{ d.totalcredu | formataDinheiro }}</td>
									<td class="pl-4 pr-0 pt-0 pb-0" nowrap>{{ d.totalvendaprazo | formataDinheiro }}</td>
									<td class="pl-4 pr-8 pt-0 pb-0" nowrap>{{ (d.indice).toFixed(2) }}%</td>
								</tr>
							</tbody>
							<tfoot class="footThree text-capitalize" v-if="dadosComparativo.lista != null">
								<tr class="cursor-normal">
									<td nowrap class="pl-8 pr-0 pt-0 pb-0 bold-text"></td>
									<td nowrap class="pl-4 pr-0 pt-0 pb-0 bold-text">Totais:</td>
									<td nowrap class="pl-4 pr-0 pt-0 pb-0 bold-text">{{ dadosComparativo.totalcredu |
										formataDinheiro }}</td>
									<td nowrap class="pl-4 pr-0 pt-0 pb-0 bold-text">{{ dadosComparativo.totalvendaprazo |
										formataDinheiro }}</td>
									<td nowrap class="pl-4 pr-8 pt-0 pb-0 bold-text">{{ ((dadosComparativo.totalcredu /
										dadosComparativo.totalvendaprazo) * 100).toFixed(2) }}%</td>

								</tr>
							</tfoot>
							<tbody v-else>
								<tr>
									<td class="text-center" colspan="5">Nenhum registro encontrado.</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>

			</v-card>

		</v-col>
	</v-row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
	name: "CreduLancamento",
	data: () => ({
		dialog: false,
		dialogCredu: false,
		dialogCX: false,
		dialogCredu01: false,
		dialogCredu02: false,
		dialogCredu03: false,
		dialogCredu04: false,
		corAtencao: "",
		anoAtual: new Date(),
		busca: { data: "", anoSelecionado: "" },
		datas2: ["2023", "2024"],
		datas: [{ name: "", value: "" }],
		anoAtualSelecionadoVisualizar: null,
    anoAnterior: null,
		carregando: true,
		carregando2: false,
		carregando3: false,
		carregandoSkeleton: true,
		carregandoSkeleton2: true,
		dados: {
			creduacumulado: [],
			firstmonth: [],
			secondmonth: [],
			thirdmonth: [],
			fourthmonth: [],
		},
		dadosAcumulado: {
			lista: [],
			totalmes01: 0,
			totalmes02: 0,
			totalmes03: 0,
			totalmes04: 0
		},
		dadosRecebido: {
			lista: [],
			total: 0,
			totalfiliais: {},
			totalmes: {},
		},
		dadosComparativo: {
			lista: [],
			totalcredu: 0,
			totalvendaprazo: 0
		},
		dadosCXPrazo: {
			lista: [],
			totalempresa: [],
			totalmes: [],
			totalvendaprazo: 0
		},
		totaisMeses: [],
		totalGeral: 0,
		panels: [4],
		panelacumulado: [1],
		headerOpen: false,
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
		meses() {
			return [
				"2022-10", "2022-11", "2022-12", "2023-01", "2023-02", "2023-03",
				"2023-04", "2023-05", "2023-06", "2023-07", "2023-08", "2023-09"
			];
		},
		empresasAgrupadas() {
			// Agrupar os dados por empresa e organizar por mês
			const empresas = {};

			this.dadosRecebido.lista.forEach(item => {
				if (!empresas[item.empresa]) {
					empresas[item.empresa] = {
						idempresa: item.idempresa,
						empresa: item.empresa,
						meses: new Array(12).fill(0), // Array para armazenar os meses
						total: 0 // Inicializa o total
					};
				}
				empresas[item.empresa].meses[item.mes - 1] = item.totalrecebido; // Preenche o mês correto
				empresas[item.empresa].total += item.totalrecebido; // Incrementa o total
			});

			// Converte o objeto para um array de empresas
			return Object.values(empresas);
		},
		empresasAgrupadasCX() {
			// Agrupar os dados por empresa e organizar por mês
			const empresas = {};

			this.dadosCXPrazo.lista.forEach(item => {
				if (!empresas[item.empresa]) {
					empresas[item.empresa] = {
						idempresa: item.idempresa,
						empresa: item.empresa,
						meses: {}, // Array para armazenar os meses
						total: 0 // Inicializa o total
					};
				}
				// Adiciona ou atualiza o valor de vendaprazo para a data específica
				empresas[item.empresa].meses[item.data] = item.vendaprazo;
				empresas[item.empresa].total += this.dadosCXPrazo.totalempresa[item.idempresa]; // Incrementa o total
			});

			// Converte o objeto para um array de empresas
			return Object.values(empresas);
		},
	},
	methods: {
		listar() {
			this.carregando = true;
			this.carregandoSkeleton = true;
			this.carregandoSkeleton2 = true;
			this.dados.firstmonth = [];
			this.dados.secondmonth = [];
			this.dados.thirdmonth = [];
			this.dados.fourthmonth = [];
			this.dados.creduacumulado = [];
			return axios
				.post(`${this.backendUrl}credu/dashboard/listar`, {
					data: this.busca.data,
				})
				.then((res) => {
					this.busca.anoSelecionado = this.busca.data;
					this.dados.firstmonth = res.data.firstmonth;
					this.dados.secondmonth = res.data.secondmonth;
					this.dados.thirdmonth = res.data.thirdmonth;
					this.dados.fourthmonth = res.data.fourthmonth;
					this.dados.creduacumulado = res.data.creduacumulado;
          this.getAnoAnterior();
					this.listarAcumulado();
					this.listarRecebido();
					this.listarComparativo();
					this.listarComparativoCXPrazo();
					this.carregando = false;
					this.carregandoSkeleton = false;
				})
				.catch(() => {
					this.dados.firstmonth = [];
					this.dados.secondmonth = [];
					this.dados.thirdmonth = [];
					this.dados.fourthmonth = [];
					this.carregando = false;
					this.carregandoSkeleton = false;
				});
		},
		listarAcumulado() {
			this.carregando3 = true;
			this.dadosAcumulado.lista = [];
			this.dadosAcumulado.totalmes01 = 0;
			this.dadosAcumulado.totalmes02 = 0;
			this.dadosAcumulado.totalmes03 = 0;
			this.dadosAcumulado.totalmes04 = 0;
			return axios
				.post(`${this.backendUrl}credu/acumulado/listar`, {
					data: this.busca.data,
				})
				.then((res) => {
					this.busca.anoSelecionado = this.busca.data;
					this.dadosAcumulado.lista = res.data.lista;
					this.dadosAcumulado.totalmes01 = res.data.totalmes01;
					this.dadosAcumulado.totalmes02 = res.data.totalmes02;
					this.dadosAcumulado.totalmes03 = res.data.totalmes03;
					this.dadosAcumulado.totalmes04 = res.data.totalmes04;
					this.carregando3 = false;
					this.carregandoSkeleton = false;
				})
				.catch(() => {
					this.dadosAcumulado.lista = [];
					this.dadosAcumulado.totalmes01 = 0;
					this.dadosAcumulado.totalmes02 = 0;
					this.dadosAcumulado.totalmes03 = 0;
					this.dadosAcumulado.totalmes04 = 0;
					this.carregando3 = false;
					this.carregandoSkeleton = false;
				});
		},
		listarRecebido() {
			this.carregando3 = true;
			this.dadosRecebido.lista = [];
			this.dadosRecebido.total = 0;
			this.dadosRecebido.totalfiliais = {};
			this.dadosRecebido.totalmes = {};
			return axios
				.post(`${this.backendUrl}credu/recebido/listar`, {
					data: this.busca.data,
				})
				.then((res) => {
					this.busca.anoSelecionado = this.busca.data;
					this.dadosRecebido.lista = res.data.lista;
					this.dadosRecebido.total = res.data.total;
					this.dadosRecebido.totalfiliais = res.data.totalfiliais;
					this.dadosRecebido.totalmes = res.data.totalmes;
					this.carregando3 = false;
					this.carregandoSkeleton = false;
				})
				.catch(() => {
					this.dadosRecebido.lista = [];
					this.dadosRecebido.total = 0;
					this.dadosRecebido.totalfiliais = {};
					this.dadosRecebido.totalmes = {};
					this.carregando3 = false;
					this.carregandoSkeleton = false;
				});
		},
		listarComparativo() {
			this.dadosComparativo = { lista: [], totalcredu: 0, totalvendaprazo: 0 }
			this.carregando = true;
			this.carregandoSkeleton2 = true;
			return axios
				.post(`${this.backendUrl}credu/comparativo/listar`, {
					data: this.busca.data,
				})
				.then((res) => {
					this.dadosComparativo.lista = res.data.lista;
					this.dadosComparativo.totalcredu = res.data.totalcredu;
					this.dadosComparativo.totalvendaprazo = res.data.totalvendaprazo;
					this.carregando = false;
					this.carregandoSkeleton2 = false;
				})
				.catch(() => {
					this.dadosComparativo.lista = [];
					this.dadosComparativo.totalcredu = 0;
					this.dadosComparativo.totalvendaprazo = 0;
					this.carregando = false;
					this.carregandoSkeleton2 = false;
				});
		},
		listarComparativoCXPrazo() {
			this.dadosCXPrazo = { lista: [], totalempresa: [], totalvendaprazo: 0, totalmes: [] }
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}credu/comparativo/caixaprazo/listar`, {
					data: this.busca.data,
				})
				.then((res) => {
					this.dadosCXPrazo.lista = res.data.lista;
					this.dadosCXPrazo.totalempresa = res.data.totalempresa;
					this.dadosCXPrazo.totalmes = res.data.totalmes;
					this.dadosCXPrazo.totalvendaprazo = res.data.totalvendaprazo;
					this.carregando = false;
				})
				.catch(() => {
					this.dadosCXPrazo.lista = [];
					this.dadosCXPrazo.totalempresa = [];
					this.dadosCXPrazo.totalmes = [];
					this.dadosCXPrazo.totalvendaprazo = 0;
					this.carregando = false;
				});
		},
		periodos() {
			return axios
				.post(`${this.backendUrl}credu/data/listar`, {})
				.then((res) => {
					this.datas = res.data.lista.map((v) => {
						return {
							name: v.anoinicio.toString(),
							value: v.anoinicio.toString(),
						};
					});
				});
		},
		getAnoAnterior() {
			this.anoAtualSelecionadoVisualizar = this.busca.anoSelecionado;
			this.anoAnterior = this.anoAtualSelecionadoVisualizar - 1;
		},
		async init() {
			this.busca.idfilial = this.usuario.idfilial;
			this.busca.data = this.anoAtual.getFullYear().toString();
			this.busca.anoSelecionado = this.anoAtual.getFullYear();
			this.getAnoAnterior();
			await this.periodos();
			await this.listar();
			if (this.dadosAcumulado.lista == null) {
				await this.listarAcumulado();
			}
			if (this.dadosRecebido.lista == null) {
				await this.listarRecebido();
			}
			if (this.dadosComparativo.lista == null) {
				await this.listarComparativo();
			}
			if (this.dadosCXPrazo.lista == null) {
				await this.listarComparativoCXPrazo();
			}

		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
.table {
	margin: 0px 0px 10px 0px;
}

.header th {
	background-color: #CFD8DC !important;
	height: 25px !important;
	font-size: 18px !important;
}


.headerTwo th {
	background-color: #CFD8DC !important;
	height: 25px !important;
	font-size: 14px !important;
}

.headerThree th {
	background-color: #CFD8DC !important;
	height: 25px !important;
	font-size: 20px !important;
}

.body td {
	height: 20px !important;
	font-size: 16px !important;
}

.bodyThree td {
	height: 24px !important;
	font-size: 18px !important;
}

.bold-text {
	font-weight: bold;
}

.foot td {
	height: 20px !important;
	font-size: 16px !important;
}

.footThree td {
	height: 24px !important;
	font-size: 18px !important;
}


::v-deep .custom-padding .v-expansion-panel-content__wrap {
	padding: 0 !important;
}
</style>